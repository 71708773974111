<template>
  <b-row>
    <!-- :to="{ name: 'purchaseorders-add' }" -->
    <b-col cols="12">
      <div class="d-flex justify-content-end my-1">
        <b-button variant="primary" :to="{ name: 'purchaseorder-add' }">
          <span class="text-nowrap">Create Purchase Order</span>
        </b-button>
        <b-button variant="primary" class="ml-2" :to="{ name: 'lowstock-list' }">
          <span class="text-nowrap">Low Stock Refill</span>
        </b-button>
      </div>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>

    <b-col md="10" class="my-1">
      <b-form-group label="Filter" label-cols-sm="8" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="purchaseOrderList"
        :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(supplierName)="data">
          {{ getSupplierNameById(data.item.supplierId) }}
        </template>
        <template #cell(createdOn)="data">
          {{ formatDate(data.item.createdOn) }}

        </template>
        <template #cell(issueDate)="data">
          {{ formatDate(data.item.issueDate) }}

        </template>
        <template #cell(total)="data">
          Rs {{ data.item.total.toFixed(2) }}
        </template>
        <template #cell(isActive)="data">
          <b-badge v-if="data.item.isActive" variant="primary"> Active </b-badge>
          <b-badge v-else variant="danger"> Inactive </b-badge>
        </template>
        <template #cell(orderType)="data">
          <b-badge v-if="data.item.orderType === 0" variant="primary">
            Fixed Order
          </b-badge>
          <b-badge v-else-if="data.item.orderType === 1" variant="primary">
            Variable Order
          </b-badge>
          <b-badge v-else-if="data.item.orderType === 2" variant="primary">
            OneOff Order
          </b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge v-if="data.item.status === 0" variant="info"> New </b-badge>
          <b-badge v-else-if="data.item.status === 1" variant="primary">
            Completed
          </b-badge>
          <b-badge v-else-if="data.item.status === 2" variant="danger">
            Cancelled
          </b-badge>
          <b-badge v-else variant="danger"> Blocked </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item :to="getPurchaseOrderId(data.item.id)">
              <feather-icon icon="EyeIcon" class="mr-50" />
              <span>View</span>
            </b-dropdown-item>
            <b-dropdown-item @click="getPurchaseOrderIdComplete(data.item)">
              <feather-icon icon="Edit2Icon" class="mr-50" />

              <span>Complete</span>
            </b-dropdown-item>
            <b-dropdown-item @click="CancelPO(data.item)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Cancel</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="d-flex justify-content-center mb-1">
          <b-spinner
            variant="success"
            label="Spinning"
            block
            v-if="loading"
            style="width: 3rem; height: 3rem"
          ></b-spinner>
        </div>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
        class="my-0" />
        <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";

import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      supplierName: "",
      purchaseOrderData: [],
      perPage: 5,
      dayjs,
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "orderNumber", label: "ORDER NUMBER", sortable: true },
        { key: "supplierName", label: "SUPPLIER NAME" },
        { key: "issueDate", label: "ISSUE DATE" },
        { key: "createdOn", label: "CREATED ON" },
        { key: "status", label: "STATUS" },
        { key: "total", label: "TOTAL" },
        "actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("purchaseOrderModule", {
      purchaseOrderList: "purchaseorders",
      purchaseorderId: "purchaseorderId",
      loading: "loading",
    }),
    ...mapGetters("supplierModule", {
      supplierList: "suppliers",
      supplierLoading: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    getpurchaseOrderData(data) {
      console.log(data);
      this.purchaseOrderData = data;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    getPurchaseOrderId(item) {
      return "/purchaseorder/view/" + item;
    },
    removePurchaseOrder(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removePurchaseOrdersAction(id);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Your file is safe :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },

    CancelPO(item) {
      let payload = {
        id: item.id,
        status: 2,
        supplierId: item.supplierId,
        quantity: item.quantity,
        totalCost: item.totalCost,
        issueDate: item.issueDate,
        balance: item.balance,
        salesPersonId: item.salesPersonId,
        paymentMethod: item.paymentMethod,
        dueDate: item.dueDate,
        orderNumber: item.orderNumber,
        subTotal: item.subTotal,
        discount: item.discount,
        tax: item.tax,
        total: item.total,
        note: item.note,
        warehouseId: item.warehouseId,
        purchaseOrderItems: item.purchaseOrderItems,
      }
      this.updatePurchaseOrdersAction(payload).then(() => {
        this.$swal({
          icon: "success",
          title: "Success!",
          text: "Purchase Order Cancelled",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        this.getPurchaseOrdersListAction();
      }).catch(() => {
        this.$swal({
          icon: "error",
          title: "Error!",
          text: "Purchase Order Not Cancelled",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      });
    },
    getPurchaseOrderIdComplete(item) {
      let payload = {
        id: item.id,
        status: 1,
        supplierId: item.supplierId,
        quantity: item.quantity,
        totalCost: item.totalCost,
        issueDate: item.issueDate,
        balance: item.balance,
        salesPersonId: item.salesPersonId,
        paymentMethod: item.paymentMethod,
        dueDate: item.dueDate,
        orderNumber: item.orderNumber,
        subTotal: item.subTotal,
        discount: item.discount,
        tax: item.tax,
        total: item.total,
        note: item.note,
        warehouseId: item.warehouseId,
        purchaseOrderItems: item.purchaseOrderItems,
      }
      this.updatePurchaseOrdersAction(payload).then(() => {
        this.$swal({
          icon: "success",
          title: "Success!",
          text: "Purchase Order Completed",
          customClass: {
            confirmButton: "btn btn-success",
          },
        }).then(() => {
         window.location.reload();
        });
        this.getPurchaseOrdersListAction();
      }).catch(() => {
        this.$swal({
          icon: "error",
          title: "Error!",
          text: "Purchase Order Not Completed",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      });
    },
    getSupplierNameById(id) {
      let supplier = this.supplierList.filter((obj) => {
        return obj.id === id;
      });
      if (supplier.length > 0) {
        return supplier[0].name;
      }
    },
    ...mapActions("purchaseOrderModule", [
      "getPurchaseOrdersListAction",
      "removePurchaseOrdersAction",
      "updatePurchaseOrdersAction",
    ]),
    ...mapActions("supplierModule", ["getSupplierListAction"]),
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  async mounted() {
    await this.getPurchaseOrdersListAction()
      .then(() => {
        this.totalRows = this.purchaseOrderList.length;
      })
      .catch((response) => {
        console.log(response);
      });
    await this.getSupplierListAction();
  },
};
</script>
